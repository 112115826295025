@font-face {
  font-family: Arimo-Regular;
  src: url('./assets/fonts/Arimo-Regular.ttf');
}

@font-face {
  font-family: Arimo-Bold;
  src: url('./assets/fonts/Arimo-Bold.ttf');
}

@font-face {
  font-family: Gilroy-Bold;
  src: url('./assets/fonts/Gilroy-Bold.otf');
}

@font-face {
  font-family: Gilroy-Medium;
  src: url('./assets/fonts/Gilroy-Medium.otf');
}

@font-face {
  font-family: LucidaGrande;
  src: url('./assets/fonts/LucidaGrande.ttf');
}

@font-face {
  font-family: SFProText-Regular;
  src: url('./assets/fonts/SFProText-Regular.otf');
}

@font-face {
  font-family: SFProText-Semibold;
  src: url('./assets/fonts/SFProText-Semibold.otf');
}

@font-face {
  font-family: TTNorms-Medium;
  src: url('./assets/fonts/TTNorms-Medium.otf');
}

@font-face {
  font-family: UniSans;
  src: url('./assets/fonts/UniSans.otf');
}

@font-face {
  font-family: AmaticSC-Regular;
  src: url('./assets/fonts/AmaticSC-Regular.ttf');
}

@font-face {
  font-family: FrankRuhlLibre-Regular;
  src: url('./assets/fonts/FrankRuhlLibre-Regular.ttf');
}

@font-face {
  font-family: Fredoka-Regular;
  src: url('./assets/fonts/Fredoka-Regular.ttf');
}

@font-face {
  font-family: OpenSansHebrew-Regular;
  src: url('./assets/fonts/OpenSansHebrew-Regular.ttf');
}

@font-face {
  font-family: Ubuntu-Regular;
  src: url('./assets/fonts/Ubuntu-Regular.ttf');
}

@font-face {
  font-family: Ubuntu-Bold;
  src: url('./assets/fonts/Ubuntu-Bold.ttf');
}
