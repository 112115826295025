body {
  touch-action: none;
  overflow: hidden;
  overscroll-behavior: contain;
}

/* Style the scrollbar itself */
::-webkit-scrollbar {
  width: 6px;
  height: 6px;
}

/* Style the handle (part that you drag) */
::-webkit-scrollbar-thumb {
  background-color: darkgrey; /* color of the thumb */
  border-radius: 10px; /* roundness of the ends */
  border: 3px solid transparent;
}

/* Style the track (part the thumb slides within) */
::-webkit-scrollbar-track {
  background: transparent; /* color of the track */
}

/* Style the buttons (arrows at the top and bottom of the scrollbar) */
::-webkit-scrollbar-button {
  display: none; /* typically not displayed */
}

.RootWrapper {
  display: flex;
  height: 100vh;
  margin: 0 auto;
  width: 1152px;
}
.RootWrapper.full-width {
  width: 100vw;
}

input,
textarea {
  outline: none;
}

.Toastify__toast--default {
  background: #1253fa;
  color: #fff;
}

[data-cssforweb='modal']:not([data-is-fullscreen]) {
  min-width: 1152px;
  margin: 0 auto !important;
}

.PhoneInput {
  flex: 1;
  z-index: 1;
  padding: 0px 10px 0px 15px;
  border-radius: 30px;
  background-color: white;
  border: 1px solid #c3c3c3;
}

.PhoneInput--submit-btn {
  margin-right: -40px;
}

.PhoneInput--rtl.PhoneInput--submit-btn {
  margin-left: -40px;
  margin-right: 0;
}

.PhoneInputCountrySelectArrow {
  border-color: #fff;
}

.PhoneInputCountrySelect option[value='ZZ'] {
  display: none;
}

.PhoneInput input {
  background: transparent;
  color: rgb(52, 67, 86);
  font-size: 16px;
  font-family: 'Gilroy-Medium';
  flex: 1;
  padding-top: 4px;
  padding-left: 16px;
  height: calc(100% - 4px);
  border-top: none;
  border-bottom: none;
  border-right: none;
  border-left: 1px solid #c3c3c3;
}

.PhoneInput--rtl input {
  border-left: none;
  border-right: 1px solid #c3c3c3;
  padding-right: 24px;
  padding-left: 2px;
}

.PhoneInputInput {
  width: 0;
}

.PhoneInputCountry {
  border-radius: 100px;
  width: 21px;
  height: 21px;
  border: none;
  align-self: auto;
  overflow: hidden;
  display: flex;
  justify-content: center;
  align-content: center;
  margin-right: 16px;
}

.PhoneInput--rtl .PhoneInputCountry {
  margin-right: 0;
  margin-left: 16px;
}

.PhoneInput--force-ltr input {
  direction: ltr;
}

.PhoneInputCountryIcon {
  width: 100%;
  height: 100%;
  display: flex;
  justify-content: center;
  align-content: center;
}

.PhoneInputCountryIcon--border {
  box-shadow: none;
  background: none;
}

.PhoneInputCountrySelectArrow {
  display: none;
}

.PhoneInputCountryIconImg {
  width: auto;
}

#root .preview-play-button {
  font-size: 2.2em;
  border-radius: 2.2em;
  width: 2.2em;
  height: 2.2em;
  line-height: 1.8em;
  border-width: 5px;
  margin-top: -1em;
  margin-left: -1em;
}

#root .preview-play-button:before {
  font-size: 1.8em;
}

.grecaptcha-badge {
  display: none;
}
